import React, { FC } from 'react';

type Props = {
  source: any;
  width?: number;
  height?: number;
  alt?: string;
  classes?: string;
};

const Image: FC<Props> = (props) => {
  const { source, width = 24, height = 24, alt = 'icon', classes = '' } = props;

  return (
    <img
      src={source}
      alt={alt}
      width={width}
      height={height}
      className={`${classes}`}
    />
  )
}

export default Image;
