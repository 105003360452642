import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Box, Button, Divider, Stack, Typography, Drawer, CssBaseline, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  AutoCompleteState,
  CollapsibleTable,
  CustomSelect,
  DenseTable,
  Image
} from '../../components/components';
import { ICONS, IMAGES, THEME, TYPES } from '../../utils';
import { loads } from '../../utils/mockData';
import CustomButton from '../../components/components/CustomButton';
import { Any } from '../../utils/types';
import DetailDrawer from '../../components/components/DetailDrawer';
import LoadForm from './LoadForm';

const drawerWidth = 400;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  position: 'relative',
  '@media screen and (max-width: 640px)': {
    display: 'none'
  }
}));

const SearchLoads = () => {
  const initState = {
    type: '',
    origin: {
      name: '',
      city: '',
      state: '',
    },
    destination: {
      name: '',
      city: '',
      state: '',
    },
    broker: {
      name: '',
      address: '',
    },
    rate: 0,
    equipment: {
      type: 'Van'
    },
    pickDate: new Date(),
    dropDate: new Date(),
    length: 0,
    width: 0,
    postedDate: new Date(),
  }

  const [load, setLoad] = useState(initState);
  const [action, setAction] = useState('Save');
  const [searchParams] = useSearchParams();
  const [searchInfo, setSearchInfo] = useState({ origin: '', destination: '', loadType: 'all' });
  const [selected, setSelected] = useState<number>(0);
  const [selectedLoad, setSelectedLoad] = useState<Any>(null);
  const [isLoadFormOpen, setLoadFormOpen] = useState(false);

  useEffect(() => {
    const loadin = searchParams.get('loadin');
    const loadout = searchParams.get('loadout');
    if (loadin) setSearchInfo(v => ({ ...v, origin: loadin }))
    if (loadout) setSearchInfo(v => ({ ...v, destination: loadout }))
  }, [])

  const handleChangeSearch = (field: string, value: string) => {
    setSearchInfo(v => ({ ...v, [field]: value }))
  }

  const handleTableRow = (id: number) => {    
    const load = loads.filter(load => load.id === id)[0];
    setSelected(id);
    setSelectedLoad(load);
  }

  const postLoad = (load: TYPES.Load) => {

  };

  const updateLoad = (load: TYPES.Load) => {

  }

  return (
    <Stack direction='column' spacing={4} px={{ xs: 5, lg: 9 }} py={{ xs: 6, lg: 9 }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' zIndex={50}>
        <CustomButton
          title='Post Load'
          icon={ICONS.plus}
          styles={{
            backgroundColor: THEME.COLORS.blue,
            '&:hover': {
              backgroundColor: `${THEME.COLORS.blue}A0`,
            },
            width: '132px', gap: 2,
            display: selected ? 'none' : 'flex',
            '@media screen and (max-width: 640px)': {
              display: 'none'
            }
          }}
          onAction={() => { setLoadFormOpen(true) }}
        />
        <Stack
          direction='row'
          alignItems='center'
          justifyContent={{ xs: 'space-between', lg: selected ? 'space-between' : 'center' }}
          gap={{ xs: 1, lg: 2 }}
          width={{ xs: '100%', lg: selected ? '100%' : 'max-content' }}
        >
          <div className='flex flex-row items-center gap-1 lg:gap-2'>
            <AutoCompleteState
              name='Origin'
              value={searchInfo.origin}
              styles={{
                width: '136px', gap: 2,
                '@media screen and (max-width: 640px)': {
                  width: '84px',
                  height: '40px',
                }
              }}
              onChange={(value) => handleChangeSearch('origin', value)}
            />
            <AutoCompleteState
              name='Destination'
              value={searchInfo.destination}
              styles={{
                width: '136px',
                '@media screen and (max-width: 640px)': {
                  width: '108px',
                  height: '40px',
                }
              }}
              onChange={(value) => handleChangeSearch('destination', value)}
            />
            <CustomSelect
              placeholder='Load Type'
              value={searchInfo.loadType}
              data={[
                { label: 'Load Type', value: 'all' },
                { label: 'Load In', value: 'in' },
                { label: 'Load Out', value: 'out' },
              ]}
              styles={{
                width: '136px',
                '@media screen and (max-width: 640px)': {
                  width: '108px', height: '40px',
                }
              }}
              onSelect={(value) => handleChangeSearch('loadType', value)}
            />
          </div>
          <CustomButton
            title='Search'
            icon={ICONS.search}
            styles={{
              width: '132px', gap: 2,
              '@media screen and (max-width: 640px)': {
                width: '40px',
                height: '40px',
                minWidth: '40px',
              }
            }}
            textStyles={{
              '@media screen and (max-width: 640px)': {
                display: 'none',
              }
            }}
            onAction={() => { }}
          />
        </Stack>
      </Stack>
      <CssBaseline />
      <Stack direction='row' gap={5} position='relative' sx={{ overflowX: 'hidden' }}>
        <Main open={selected ? true : false}>
          <DenseTable
            data={loads}
            selected={selected}
            onAction={handleTableRow}
          />
        </Main>
        <Stack display={{ xs: 'flex', lg: 'none' }} direction='column' width='100%'>
          <CollapsibleTable data={loads} />
        </Stack>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              position: 'absolute',
            },
            '@media screen and (max-width: 640px)': {
              display: 'none'
            }
          }}
          variant="persistent"
          anchor="right"
          open={selected ? true : false}
        >
        {selected &&
        <DetailDrawer
          load={selectedLoad}
          onCloseDrawer={()=>setSelected(0)}
        />}
      </Drawer>
      </Stack>
      <Dialog
        open={isLoadFormOpen}
      >
        <DialogTitle>Post Load -- In Progress</DialogTitle>
        <DialogContent>
          <LoadForm
            load={load}
            action={action}
            fnOnSaveLoad={load => postLoad(load)}
            fnOnUpdateLoad={load => updateLoad(load)}
          />
        </DialogContent>
      </Dialog>
    </Stack>
  )
};

export default SearchLoads;
