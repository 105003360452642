import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

const FONT_PRIMARY = 'Poppins, sans-serif';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 1.24,
    fontSize: pxToRem(64),
    letterSpacing: -0.64,
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
    color: '#232323',
  },
  h2: {
    fontWeight: 700,
    lineHeight: 1.3,
    fontSize: pxToRem(50),
    letterSpacing: -0.5,
    ...responsiveFontSizes({ sm: 42, md: 46, lg: 50 }),
    color: '#232323',
  },
  h3: {
    fontWeight: 600,
    lineHeight: 1.4,
    fontSize: pxToRem(32),
    letterSpacing: -0.32,
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
    color: '#232323',
  },
  h4: {
    fontWeight: 600,
    lineHeight: 1.4,
    fontSize: pxToRem(24),
    letterSpacing: -0.24,
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
    color: '#232323',
  },
  h5: {
    fontWeight: 600,
    lineHeight: 1.4,
    fontSize: pxToRem(18),
    letterSpacing: -0.18,
    ...responsiveFontSizes({ sm: 17, md: 18, lg: 18 }),
    color: '#232323',
  },
  h6: {
    fontWeight: 600,
    lineHeight: 1.4,
    fontSize: pxToRem(16),
    letterSpacing: -0.16,
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
    color: '#232323',
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: -0.16,
    fontSize: pxToRem(16),
    // color: '#6A6969',
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    letterSpacing: -0.14,
    fontSize: pxToRem(14),
    // color: '#6A6969',
  },
  body0: {
    fontWeight: 600,
    lineHeight: 1,
    letterSpacing: -0.18,
    fontSize: pxToRem(18),
    // color: '#6A6969',
  },
  body1: {
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: -0.16,
    fontSize: pxToRem(16),
    // color: '#6A6969',
  },
  body2: {
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: -0.14,
    fontSize: pxToRem(14),
    // color: '#6A6969',
  },
  body3: {
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: -0.12,
    fontSize: pxToRem(12),
    // color: '#6A6969',
  },
  caption: {
    lineHeight: 1.5,
    letterSpacing: -0.12,
    fontSize: pxToRem(12),
    // color: '#6A6969',
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: -0.12,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
    // color: '#6A6969',
  },
  button: {
    fontWeight: 400,
    lineHeight: 24 / 14,
    letterSpacing: -0.16,
    fontSize: pxToRem(16),
    textTransform: 'capitalize',
  },
};

export default typography;
