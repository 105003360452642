import { Outlet, Route, Routes } from "react-router-dom"
import { Dashboard, PrivateLoads, Reports, SearchLoads, SearchTrucks, Settings } from "../pages"

import DashboardLayout from "../pages/layout"
import LBSidebar from "../components/layouts/LBSidebar"
import LandingPage from "../pages/home/LandingPage"

const AppRoutes = () => {

  return (
    <Routes>
      <Route path="app" element={<DashboardLayout />} >
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="loads" element={<SearchLoads />} />
        <Route path="trucks" element={<SearchTrucks />} />
        <Route path="private-loads" element={<PrivateLoads />} />
        <Route path="reports" element={<Reports />} />
        <Route path="settings" element={<Settings />} />
      </Route>
      <Route path="/" element={<Outlet /> } >
        <Route path="/" element={<LandingPage />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes;
