import { team } from "../../utils/landing_page";

const AboutUs = () => {
    return (
      <div id="company" className="mt-20 tracking-wide">
        <h2 className="text-3xl sm:text-5xl lg:text-6xl text-center my-10 lg:my-20">
          Company
        </h2>
        <div className="flex flex-wrap justify-center">
          {team.map((person, index) => (
            <div key={index} className="w-full sm:w-1/2 lg:w-1/3 px-4 py-2">
              <div className="rounded-md p-6 text-md border-neutral-800 font-thin">                
                <div className="flex mt-8 items-start">
                  <img
                    className="w-24 h-24 mr-6 rounded-full border border-neutral-300"
                    src={person.image}
                    alt=""
                  />
                  <div className="text-lg font-normal text-neutral-600">
                    <h4>{person.name}</h4>
                    <span>
                      {person.title}
                    </span>
                  </div>
                </div>
                <p className="text-sm font-normal italic text-neutral-600">{person.text}</p>                
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default AboutUs;