import { type Any } from '../../utils/types';

export default function Button(theme: Any) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: -0.12,
          boxShadow: theme.customShadows.none,
          borderRadius: '16px',
          '&:hover': {
            boxShadow: theme.customShadows.none,
          },
        },
        sizeLarge: {
          height: 48,
        },
        sizeMedium: {
          borderRadius: '12px',
        },
        sizeSmall: {
          borderRadius: '8px',
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        containedDark: {
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        },
        containedLight: {
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        },
        containedDarkGrey: {
          '&:hover': {
            backgroundColor: theme.palette.darkGrey.light,
          },
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
