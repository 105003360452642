import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, sidebarClasses } from 'react-pro-sidebar';

import { MENU_LIST } from '../../utils/constants';
import { ICONS, IMAGES, THEME } from '../../utils';
import useAppData from '../../hooks/useAppData';
import { isMobile } from '../../utils/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuOutlined } from "@mui/icons-material";
import { Box, IconButton } from '@mui/material';

function LBSidebar() {
  const { sidebar, toggleSidebar } = useAppData();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Sidebar
      collapsed={isCollapsed}
      onBackdropClick={() => toggleSidebar(false)}
      breakPoint="md"
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: THEME.COLORS.dark,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
    >
      <div className='flex flex-col'>
        <div className='h-20 flex flex-row items-center gap-4 px-5 border-b border-[#494950]'>
          <div className='w-12 h-12 flex justify-center items-center bg-white rounded-full'>
            <img src={ICONS.logo} alt='logo' className='w-6 h-6' />
          </div>
          <div className='flex flex-col'>
            <span className='text-white font-bold text-2xl'>FreightSmart</span>
            <span className='text-[#C3C4CF] text-xs'>AI Enabled Freight Platform</span>
          </div>
        </div>
        <div className='flex flex-row items-center px-5 gap-3 my-9'>
          <img src={IMAGES.avatar} alt='avatar' width={52} height={52} className='rounded-full' />
          <div className='flex flex-col'>
            <span className='text-white font-semibold text-lg'>Luke Wright</span>
            <span className='text-[#C3C4CF] text-sm'>CEO at ABC Logistics</span>
          </div>
        </div>
        <Menu
          menuItemStyles={{
            button: ({ level, active }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  margin: '10px 20px',
                  borderRadius: 10,
                  color: 'white',
                  ":hover": {
                    backgroundColor: isMobile() ? '#FD7C07' : '#FD7C0755'
                  },
                  backgroundColor: active ? '#FD7C07' : 'transparent',
                };
            },
            label: (({ active }) => {
              return {
                color: active ? 'white' : '#B4B4C8'
              }
            })
          }}
        >
          {/* LOGO * MENU ICON */}
          {
            MENU_LIST.map((menu, index) => (
              <MenuItem
                key={index}
                active={location.pathname.includes(menu.link)}
                onClick={() => navigate(menu.link)}
                icon={<img src={location.pathname.includes(menu.link) ? menu.activeIcon : menu.icon} alt={menu.title} />}
              >
                {menu.title}
              </MenuItem>
            ))
          }
        </Menu>
      </div>
    </Sidebar >
  )
}

export default LBSidebar