import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import Image from './Image';
import { THEME } from '../../utils';

type Props = {
  title: string;
  icon?: any;
  styles?: any;
  textStyles?: any;
  onAction: () => void;
}

const CustomButton: FC<Props> = (props) => {
  const {
    title,
    icon = null,
    styles = null,
    textStyles = null,
    onAction,
  } = props;

  return (
    <Button
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        borderRadius: '4px',
        height: '44px',
        cursor: 'pointer',
        backgroundColor: THEME.COLORS.orange,
        '&:hover': {
          backgroundColor: `${THEME.COLORS.orange}A0`,
        },
        ...styles,
      }}
      onClick={() => onAction()}
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '500',
          color: 'white',
          ...textStyles,
        }}
      >
        {title}
      </Typography>
      {icon &&
        <Image source={icon} width={20} height={20} />
      }
    </Button>
  )
}

export default CustomButton;
