import { type Any } from '../../utils/types';

export default function Link(_theme: Any) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'always',
        // color: theme.palette.grey[800],
        color: 'inherit',
      },
      styleOverrides: {
        root: {
          fontWeight: '600',
        },
      },
    },
  };
}
