import React from "react";
import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";
import LBSidebar from "../components/layouts/LBSidebar";

const DashboardLayout = ({ }) => {
  return (
    <div className="flex flex-row h-screen">
      <LBSidebar />
      <div className="flex flex-col w-full">
        <div className="flex-1 w-full bg-DarkGrey overflow-auto">
          <main className="content">
            <Box m="20px" p={1}>
              <Outlet />
            </Box>
          </main>
        </div>
      </div>
    </div>
  );
}
export default DashboardLayout;