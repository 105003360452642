import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Box, Button, Divider, Stack, Typography, Drawer, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  AutoCompleteState,
  CollapsibleTable,
  CustomSelect,
  DenseTable,
  Image
} from '../../components/components';
import { ICONS, IMAGES, THEME } from '../../utils';
import { loads } from '../../utils/mockData';
import CustomButton from '../../components/components/CustomButton';

const drawerWidth = 400;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  position: 'relative',
  '@media screen and (max-width: 640px)': {
    display: 'none'
  }
}));

const SearchTrucks = () => {
  const [searchParams] = useSearchParams();
  const [searchInfo, setSearchInfo] = useState({ origin: '', destination: '', truckType: 'all' });
  const [selected, setSelected] = useState<number>(0);

  useEffect(() => {
    const loadin = searchParams.get('loadin');
    const loadout = searchParams.get('loadout');
    if (loadin) setSearchInfo(v => ({ ...v, origin: loadin }))
    if (loadout) setSearchInfo(v => ({ ...v, destination: loadout }))
  }, [])

  const handleChangeSearch = (field: string, value: string) => {
    setSearchInfo(v => ({ ...v, [field]: value }))
  }

  const handleTableRow = (id: number) => {
    setSelected(id);
  }

  return (
    <Stack direction='column' spacing={4} px={{ xs: 5, lg: 9 }} py={{ xs: 6, lg: 9 }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' zIndex={50}>
        <CustomButton
          title='Post Truck'
          icon={ICONS.plus}
          styles={{
            backgroundColor: THEME.COLORS.blue,
            '&:hover': {
              backgroundColor: `${THEME.COLORS.blue}A0`,
            },
            width: '132px', gap: 2,
            display: selected ? 'none' : 'flex',
            '@media screen and (max-width: 640px)': {
              display: 'none'
            }
          }}
          onAction={() => { }}
        />
        <Stack
          direction='row'
          alignItems='center'
          justifyContent={{ xs: 'space-between', lg: selected ? 'space-between' : 'center' }}
          gap={{ xs: 1, lg: 2 }}
          width={{ xs: '100%', lg: selected ? '100%' : 'max-content' }}
        >
          <div className='flex flex-row items-center gap-1 lg:gap-2'>
            <AutoCompleteState
              name='Origin'
              value={searchInfo.origin}
              styles={{
                width: '136px', gap: 2,
                '@media screen and (max-width: 640px)': {
                  width: '84px',
                  height: '40px',
                }
              }}
              onChange={(value) => handleChangeSearch('origin', value)}
            />
            <AutoCompleteState
              name='Destination'
              value={searchInfo.destination}
              styles={{
                width: '136px',
                '@media screen and (max-width: 640px)': {
                  width: '108px',
                  height: '40px',
                }
              }}
              onChange={(value) => handleChangeSearch('destination', value)}
            />
            <CustomSelect
              placeholder='Truck Type'
              value={searchInfo.truckType}
              data={[
                { label: 'Truck Type', value: 'all' },
                { label: 'Load In', value: 'in' },
                { label: 'Load Out', value: 'out' },
              ]}
              styles={{
                width: '136px',
                '@media screen and (max-width: 640px)': {
                  width: '108px', height: '40px',
                }
              }}
              onSelect={(value) => handleChangeSearch('truckType', value)}
            />
          </div>
          <CustomButton
            title='Search'
            icon={ICONS.search}
            styles={{
              width: '132px', gap: 2,
              '@media screen and (max-width: 640px)': {
                width: '40px',
                height: '40px',
                minWidth: '40px',
              }
            }}
            textStyles={{
              '@media screen and (max-width: 640px)': {
                display: 'none',
              }
            }}
            onAction={() => { }}
          />
        </Stack>
      </Stack>
      <CssBaseline />
      <Stack direction='row' gap={5} position='relative' sx={{ overflowX: 'hidden' }}>
        <Main open={selected ? true : false}>
          <DenseTable
            data={loads}
            selected={selected}
            onAction={handleTableRow}
          />
        </Main>
        <Stack display={{ xs: 'flex', lg: 'none' }} direction='column' width='100%'>
          <CollapsibleTable data={loads} />
        </Stack>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              position: 'absolute',
            },
            '@media screen and (max-width: 640px)': {
              display: 'none'
            }
          }}
          variant="persistent"
          anchor="right"
          open={selected ? true : false}
        >
          <Box sx={{ padding: '20px', borderBottomWidth: '1px', borderBottomColor: THEME.COLORS.borderGray }}>
            <Button
              sx={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', width: '44px', height: '44px', minWidth: '44px',
                borderWidth: '1px', borderStyle: 'solid', borderColor: THEME.COLORS.borderGray, borderRadius: 999,
              }}
              onClick={() => setSelected(0)}
            >
              <Image source={ICONS.close} />
            </Button>
          </Box>
          <Stack direction='column' p={5} gap={12}>
            <Stack direction='column'>
              <Stack direction='column' pt={6}>
                <Stack direction='row' alignItems='center' gap={3}>
                  <Box sx={{ width: '48px', height: '48px', borderWidth: '1px', borderRadius: 999, borderColor: THEME.COLORS.borderGray, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ width: '18px', height: '18px', backgroundColor: THEME.COLORS.orange, borderRadius: 999 }} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', borderRightWidth: '1px', borderColor: THEME.COLORS.borderGray, width: '144px' }}>
                    <Typography sx={{ fontSize: 12, color: THEME.COLORS.black, fontWeight: '500' }}>Original 1</Typography>
                    <Typography sx={{ fontSize: 12, lineHeight: '20px', color: THEME.COLORS.lightBlue }}>San Francisco, CA</Typography>
                  </Box>
                  <Stack direction='column'>
                    <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 12, lineHeight: '24px' }}>{format(new Date(), 'PP')}</Typography>
                    <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 12, lineHeight: '24px' }}>{format(new Date(), 'p')}</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider sx={{ width: '1px', height: '80px', marginLeft: '24px', backgroundColor: THEME.COLORS.borderGray }} />
              <Stack direction='column' pb={6}>
                <Stack direction='row' alignItems='center' gap={3}>
                  <Box sx={{ width: '48px', height: '48px', borderWidth: '1px', borderRadius: 999, borderColor: THEME.COLORS.borderGray, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Image source={ICONS.pin} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', borderRightWidth: '1px', borderColor: THEME.COLORS.borderGray, width: '144px' }}>
                    <Typography sx={{ fontSize: 12, color: THEME.COLORS.black, fontWeight: '500' }}>Original 1</Typography>
                    <Typography sx={{ fontSize: 12, lineHeight: '20px', color: THEME.COLORS.lightBlue }}>San Francisco, CA</Typography>
                  </Box>
                  <Stack direction='column'>
                    <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 12, lineHeight: '24px' }}>{format(new Date(), 'PP')}</Typography>
                    <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 12, lineHeight: '24px' }}>{format(new Date(), 'p')}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction='column'>
              <Typography sx={{ fontSize: '32px', fontWeight: '600', lineHeight: '48px', color: THEME.COLORS.black }}>
                Heading
              </Typography>
              <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 14 }}>
                The community's need for applications that can facilitate daily activities is increasing as technology advances. Currently, many companies are looking for developers so that they can sell products (goods or services) that can reach wider buyers online. We are not required to understand all the science of design.
              </Typography>
              <img src={IMAGES.map} style={{ marginTop: '24px' }} />
            </Stack>
          </Stack>
        </Drawer>
      </Stack>
    </Stack>
  )
};

export default SearchTrucks;

