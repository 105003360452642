import { type Any } from '../../utils/types';

export default function InputLabel(_theme: Any) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: '600',
        },
      },
    },
  };
}
