import { BotMessageSquare } from "lucide-react";
import { BatteryCharging } from "lucide-react";
import { Fingerprint } from "lucide-react";
import { ShieldHalf } from "lucide-react";
import { PlugZap } from "lucide-react";
import { GlobeLock } from "lucide-react";

import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import ravi from '../assets/team/ravi.jpeg';
import rocky from '../assets/team/rocky.jpeg';
import bhau from '../assets/team/bhau.png';

import user1 from "../assets/profile-pictures/user1.jpg";
import user2 from "../assets/profile-pictures/user2.jpg";
import user3 from "../assets/profile-pictures/user3.jpg";
import user4 from "../assets/profile-pictures/user4.jpg";
import user5 from "../assets/profile-pictures/user5.jpg";
import user6 from "../assets/profile-pictures/user6.jpg";

export const navItems = [
  { label: "Products", href: "#products" },
  { label: "Features", href: "#features" },
  { label: "Company", href: "#company" },
  { label: "Contact Us", href: "#contact" },
];

export const team = [
  {
    name: 'Ravisher',
    title: 'Co-Founder, CEO',
    image: ravi,
    text: `Resilient, Analytical thinker, Action Oriented.\n Machine Learning Expert. \n16+ years of experience in leading and building scalable products.`
  },
  {
    name: 'Talbir',
    title: 'Co-Founder, CMO',
    image: rocky,
    text: 'Builder, Business Savvy, Customer Oriented. Logistics Domain Expert. 25+ years of experience working in logistic industry.'    
  },
  {
    name: 'Harsimranjit',
    title: 'Co-Founder, CTO',
    image: bhau,
    text: 'Passionate, Tech Savvy, Innovative. Software & Cloud Architecture. 16+ years of experience in designing scalable solutions for different domains'    
  },  
]

export const testimonials = [
  {
    user: "John Doe",
    company: "Stellar Solutions",
    image: user1,
    text: "I am extremely satisfied with the services provided. The team was responsive, professional, and delivered results beyond my expectations.",
  },
  {
    user: "Jane Smith",
    company: "Blue Horizon Technologies",
    image: user2,
    text: "I couldn't be happier with the outcome of our project. The team's creativity and problem-solving skills were instrumental in bringing our vision to life",
  },
  {
    user: "David Johnson",
    company: "Quantum Innovations",
    image: user3,
    text: "Working with this company was a pleasure. Their attention to detail and commitment to excellence are commendable. I would highly recommend them to anyone looking for top-notch service.",
  },
  {
    user: "Ronee Brown",
    company: "Fusion Dynamics",
    image: user4,
    text: "Working with the team at XYZ Company was a game-changer for our project. Their attention to detail and innovative solutions helped us achieve our goals faster than we thought possible. We are grateful for their expertise and professionalism!",
  },
  {
    user: "Michael Wilson",
    company: "Visionary Creations",
    image: user5,
    text: "I am amazed by the level of professionalism and dedication shown by the team. They were able to exceed our expectations and deliver outstanding results.",
  },
  {
    user: "Emily Davis",
    company: "Synergy Systems",
    image: user6,
    text: "The team went above and beyond to ensure our project was a success. Their expertise and dedication are unmatched. I look forward to working with them again in the future.",
  },
];

export const features = [
  {
    icon: <SendTimeExtensionIcon />,
    text: "Dispatch Management",
    description:
      "Manage your loads with our All in One Software Suite. Support for delivery, pickup, drop, over the road load types.",
  },
  {
    icon: <Fingerprint />,
    text: "Driver Payment",
    description:
      "Support driver payments for single leg or full load. Manage expenses for drivers.",
  },
  {
    icon: <ShieldHalf />,
    text: "Accounting",
    description:
      "ChuckTruck supports auto invoice generation so you will never miss any payment from your customers.",
  },
  {
    icon: <BatteryCharging />,
    text: "Automated Load Matching",
    description:
      "Automate the process of connecting shippers with carriers, reducing manual effort and improving efficiency..",
  },
  {
    icon: <PlugZap />,
    text: "Collaboration Tools",
    description:
      "Predict and assess potential risks in the supply chain, such as delays or disruptions, and recommend mitigation strategies.",
  },
  {
    icon: <GlobeLock />,
    text: "Enhanced Customer Service",
    description:
      "Provide real-time support and updates to customers through AI-powered chatbots and virtual assistants.",
  },
];

export const checklistItems = [
  {
    title: "Code merge made easy",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
  {
    title: "Review code without worry",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
  {
    title: "AI Assistance to reduce time",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
  {
    title: "Share work in minutes",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
];

export const pricingOptions = [
  {
    title: "Free",
    price: "$0",
    features: [
      "Private board sharing",
      "5 Gb Storage",
      "Web Analytics",
      "Private Mode",
    ],
  },
  {
    title: "Pro",
    price: "$10",
    features: [
      "Private board sharing",
      "10 Gb Storage",
      "Web Analytics (Advance)",
      "Private Mode",
    ],
  },
  {
    title: "Enterprise",
    price: "$200",
    features: [
      "Private board sharing",
      "Unlimited Storage",
      "High Performance Network",
      "Private Mode",
    ],
  },
];

export const resourcesLinks = [
  { href: "#", text: "Getting Started" },
  { href: "#", text: "Documentation" },
  { href: "#", text: "Tutorials" },
  { href: "#", text: "API Reference" },
  { href: "#", text: "Community Forums" },
];

export const platformLinks = [
  { href: "#", text: "Features" },
  { href: "#", text: "Supported Devices" },
  { href: "#", text: "System Requirements" },
  { href: "#", text: "Downloads" },
  { href: "#", text: "Release Notes" },
];

export const communityLinks = [
  { href: "#", text: "Events" },
  { href: "#", text: "Meetups" },
  { href: "#", text: "Conferences" },
  { href: "#", text: "Hackathons" },
  { href: "#", text: "Jobs" },
];
