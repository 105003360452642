import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { ICONS, IMAGES, THEME, TYPES } from '../../utils';
import { format } from 'date-fns';
import { Stack } from '@mui/material';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: THEME.COLORS.heavyBlue,
    color: THEME.COLORS.profileGray,
    borderRightWidth: '1px',
    borderRightColor: THEME.COLORS.orange,
    borderRightStyle: 'solid',
    fontSize: 12,
    fontWeight: '600',
    height: '36px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    paddingLeft: '16px',
    paddingRight: '16px',
    height: '60px',
  },
  [`&.${tableCellClasses.head}:last-child`]: {
    borderRightWidth: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottomWidth: '3px',
  borderColor: THEME.COLORS.bgGray,
}));

function Row(props: { row: TYPES.LoadList }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: open ? THEME.COLORS.yellow : 'white' }}
        onClick={() => setOpen(!open)}
      >
        <StyledTableCell align="left">
          <Typography
            sx={{ color: THEME.COLORS.black, fontSize: 14, fontWeight: '500', letterSpacing: '-0.14px' }}
          >
            {row.origin.city},{row.origin.state}
          </Typography>
          <Typography
            sx={{ color: THEME.COLORS.lightBlue, fontSize: 12 }}
          >
            {row.origin.address}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography
            sx={{ color: THEME.COLORS.black, fontSize: 14, fontWeight: '500', letterSpacing: '-0.14px' }}
          >
            {row.destination.city}, {row.destination.state}
          </Typography>
          <Typography
            sx={{ color: THEME.COLORS.lightBlue, fontSize: 12 }}
          >
            {row.destination.address}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography
            sx={{ color: THEME.COLORS.black, fontSize: 14, fontWeight: '500', letterSpacing: '-0.14px' }}
          >
            Price
          </Typography>
          <Typography
            sx={{ color: THEME.COLORS.lightBlue, fontSize: 12 }}
          >
            ${row.rate}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, backgroundColor: THEME.COLORS.yellow }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <StyledTableCell align="left">
                      <Typography
                        sx={{ color: THEME.COLORS.black, fontSize: 14, fontWeight: '500', letterSpacing: '-0.14px' }}
                      >
                        Time
                      </Typography>
                      <Typography
                        sx={{ color: THEME.COLORS.lightBlue, fontSize: 12 }}
                      >
                        {format(row.pickupTime, 'P')}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ paddingLeft: '32px !important' }}>
                      <Typography
                        sx={{ color: THEME.COLORS.black, fontSize: 14, fontWeight: '500', letterSpacing: '-0.14px' }}
                      >
                        {row.broker.name}
                      </Typography>
                      <Typography
                        sx={{ color: THEME.COLORS.lightBlue, fontSize: 12 }}
                      >
                        {row.broker.address}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '84px', float: 'right' }}
                    >
                      <img src={ICONS.moreVertical} alt='icon' />
                      <img src={ICONS.arrowUpRight} alt='icon' />
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography sx={{ color: THEME.COLORS.black, fontSize: 20, fontWeight: '600' }}>
                        Heading
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 14 }}>
                        The community's need for applications that can facilitate daily activities is increasing as technology advances. Currently, many companies are looking for developers so that they can sell products (goods or services) that can reach wider buyers online. We are not required to understand all the science of design.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Box width='100%'>
                        <img src={IMAGES.map} style={{ marginTop: '8px', width: '100%' }} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

type Props = {
  data: TYPES.LoadList[];
}

const CollapsibleTable: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ borderRadius: '0px !important' }}>Origin</StyledTableCell>
            <StyledTableCell align="left">DESTINATION</StyledTableCell>
            <StyledTableCell align="left" sx={{ borderRadius: '0px !important' }}>RATE</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CollapsibleTable;
