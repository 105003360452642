import { type Any } from '../../utils/types';

export default function Stepper(theme: Any) {
  return {
    MuiStepConnector: {
      styleOverrides: {
        line: {
          '&.MuiStepConnector-lineHorizontal': {
            borderColor: theme.palette.divider,
            borderStyle: 'dashed',
            borderWidth: '1px',
            marginLeft: '2rem',
            marginRight: '2rem',
            marginTop: '7px',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-root': {
            // color: theme.palette.grey[400],
            width: '40px',
            height: '40px',
          },
        },
      },
    },
  };
}
