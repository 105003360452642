import React from 'react';

const PrivateLoads = () => {
  return (
    <div className='p-9'>
      <h1>PrivateLoads</h1>
    </div>
  )
};

export default PrivateLoads;
