import * as React from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Box,
  Button,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableBody,
  Menu,
  MenuItem,
  IconButton,
  Divider,
} from '@mui/material';
import { format } from 'date-fns';
import { ICONS, THEME, TYPES } from '../../utils';
import { ChatBubbleOutline, CompareOutlined, EmailOutlined, MoreVertOutlined } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: THEME.COLORS.heavyBlue,
    color: THEME.COLORS.profileGray,
    borderRightWidth: '1px',
    borderRightColor: THEME.COLORS.orange,
    borderRightStyle: 'solid',
    fontSize: 12,
    fontWeight: '600',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    height: '56px',
    padding: 0
  },
  [`&.${tableCellClasses.head}:last-child`]: {
    borderRightWidth: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottomWidth: '2px',
  borderColor: THEME.COLORS.bgGray,
}));

type Props = {
  data: TYPES.LoadList[];
  selected: number;
  onAction: (id: number) => void;
}

const DenseTable: React.FC<Props> = (props) => {
  const { data, selected, onAction } = props;

  const ref = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [more, setMore] = React.useState<number>(0);

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setMore && setMore(0);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setMore]);

  const handleRow = (id: number) => {
    console.log('handleRow: ', id)
    onAction(id);
  }

  const handleMore = (e: any, id: number) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setMore(id);
  }

  const handleClose = () => {
    console.log('handleClose action:')
    setMore(0);
    setAnchorEl(null);
  };

  return (
    <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" sx={{ borderRadius: '0px !important' }}>ORIGIN</StyledTableCell>
            <StyledTableCell align="center">DESTINATION</StyledTableCell>
            <StyledTableCell align="center">RATE</StyledTableCell>            
            <StyledTableCell align="center">PICKUP</StyledTableCell>
            <StyledTableCell align="center">COMPANY</StyledTableCell>
            <StyledTableCell align="center">EQUIPMENT</StyledTableCell>
            <StyledTableCell align="center" sx={{ borderRadius: '0px !important' }}>Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow
              key={row.id}
              sx={{ backgroundColor: row.id === selected ? THEME.COLORS.yellow : 'white', cursor: 'pointer' }}
              // onClick={() => handleRow(row.id)}
            >
              <StyledTableCell align="center" component="th" scope="row" onClick={() => handleRow(row.id)}>
                <Typography
                  sx={{ color: THEME.COLORS.black, fontSize: 14, fontWeight: '500', letterSpacing: '-0.14px' }}
                >
                  {row.origin.city}, {row.origin.state}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center" onClick={() => handleRow(row.id)}>
                <Typography
                  sx={{ color: THEME.COLORS.black, fontSize: 14, fontWeight: '500', letterSpacing: '-0.14px' }}
                >
                  {row.destination.city}, {row.destination.state}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center" onClick={() => handleRow(row.id)}>
                <Typography
                  sx={{ color: THEME.COLORS.heavyBlue, fontSize: 14, fontWeight: '500' }}
                >
                  ${row.rate}
                </Typography>
              </StyledTableCell>               
              <StyledTableCell align="center" onClick={() => handleRow(row.id)}>
                <Typography
                  sx={{ color: THEME.COLORS.black, fontSize: 12, fontWeight: '500', letterSpacing: '-0.14px' }}
                >
                  {format(row.pickupTime, 'PP')}
                </Typography>
              </StyledTableCell>             
              <StyledTableCell align="center" onClick={() => handleRow(row.id)}>
                <Typography
                  sx={{ color: THEME.COLORS.black, fontSize: 12, fontWeight: '500', letterSpacing: '-0.14px' }}
                >
                  {row.broker.name}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center" onClick={() => handleRow(row.id)}>
                <Typography
                  sx={{ color: THEME.COLORS.black, fontSize: 12, fontWeight: '500' }}
                >
                  {row.equipment.type}
                </Typography>
              </StyledTableCell>              
              <StyledTableCell align="center" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton
                  aria-label="settings"
                  onClick={(evt) => handleMore(evt, row.id)}
                >
                  <MoreVertOutlined />
                </IconButton>                
                <Menu
                  id={`more-menu${row.id}`}
                  open={more === row.id}
                  anchorEl={anchorEl}
                  onClose={() => handleClose()}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={handleClose}><EmailOutlined fontSize='small'/>&nbsp;&nbsp;Email Broker</MenuItem>
                  <Divider/>
                  <MenuItem onClick={handleClose}><ChatBubbleOutline fontSize='small'/>&nbsp;&nbsp;Chat</MenuItem>
                  <Divider/>
                  <MenuItem onClick={handleClose}><CompareOutlined fontSize='small'/>&nbsp;&nbsp;Compare Rate</MenuItem>
                  <Divider/>
                </Menu>
                <Button sx={{ minWidth: '40px', borderRadius: '4px', }} onClick={() => handleRow(row.id)}>
                  <img src={ICONS.arrowUpRight} alt='icon' />
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DenseTable;
