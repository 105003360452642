import React from 'react';
import './App.css';
import AppRoutes from './routes';
import { AppDataProvider } from './context/AppContext';
import ThemeProvider from './theme';

function App() {
  return (
    <ThemeProvider>
      <AppDataProvider>
        <AppRoutes />
      </AppDataProvider>
    </ThemeProvider>
  );
}

export default App;