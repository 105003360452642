import React, { FC } from 'react';
import Chart from "react-apexcharts";
import CardContainer from './CardContainer';

type Props = {
  data: number[];
  classes?: string;
}

const ActivityChartCard: FC<Props> = (props) => {
  const { data, classes } = props;

  const options = {
    chart: {
      type: 'area',
      height: 100,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      colors: ['#2E47F9'],
      width: 1,
    },
    fill: {
      type: 'gradient',
      colors: ['#2E47F9'],
      gradient: {
        type: "vertical",
        gradientToColors: ['#FFFFFF00']
      }
    },
    grid: {
      show: false,
    },
    labels: ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', ''],
    tooltip: {
      x: {
        show: true,
      },
    },
    xaxis: {
      // tickPlacement: 'between',
      // labels: {
      //   show: false,
      // },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      }
    },
    yaxis: {
      min: 40,
      labels: {
        show: false,
      },
    },
    legend: {
      horizontalAlign: 'left'
    }
  }

  return (
    <CardContainer classes={`p-7 ${classes}`}>
      <div className='flex flex-col border border-borderGray rounded-lg'>
        <div className='flex flex-row justify-between items-center px-6 py-5'>
          <span className='text-lg font-medium text-[#141522]'>Your Activity</span>
          <select className='outline-none'>
            <option value='weekly'>Weekly</option>
            <option value='weekly'>Anual</option>
          </select>
        </div>
        <div className='overflow-hidden -ml-6 -mr-3'>
          <Chart
            series={[{
              name: 'Your Activity',
              data,
            }]}
            // @ts-ignore
            options={options}
            type='area'
            height={250}
          />
        </div>
      </div>
    </CardContainer>
  )
}

export default ActivityChartCard;
