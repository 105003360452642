import React, { FC } from 'react';
import { TYPES } from '../../utils';
import { MenuItem, Select } from '@mui/material';

type Props = {
  placeholder?: string;
  value: string;
  data: TYPES.SelectItem[];
  styles?: any;
  contentClasses?: string;
  onSelect: (value: string) => void;
}

const CustomSelect: FC<Props> = (props) => {
  const { placeholder = '', styles = null, value, data, onSelect } = props;

  const handleChange = (value: string) => {
    onSelect(value);
  }

  return (
    <Select
      value={value}
      placeholder='Load Typee'
      // label="Age"
      sx={{
        height: '44px',
        borderRadius: '4px',
        outline: 'none',
        border: 'none',
        backgroundColor: 'white',
        fontSize: '12px',
        width: '136px',
        '& fieldset': {
          border: 'none',
        },
        ...styles,
      }}
      onChange={e => handleChange(e.target.value)}
    >
      {data.map((item, index) => (
        <MenuItem
          key={index}
          value={item.value}
          sx={{fontSize: '12px'}}
        >
          {item.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CustomSelect;
