import { Typography } from "@mui/material";
import { resourcesLinks, platformLinks, communityLinks } from "../../utils/landing_page"; 
import { Link } from "react-router-dom";

const Footer = () => {
  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary">
        {"Copyright © "}
        <Link color="inherit" href="https://material-ui.com/">
          www.freightsmart.ai
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }
  return (
    <footer className="mt-20 border-t py-10 border-neutral-700">
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
        <div>
          <h3 className="text-md font-semibold mb-4">Resources</h3>
          <ul className="space-y-2">
            {resourcesLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  className="text-neutral-300 hover:text-white"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-md font-semibold mb-4">Platform</h3>
          <ul className="space-y-2">
            {platformLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  className="text-neutral-300 hover:text-white"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <p>4695 Chabot Dr.</p>
          <p>Pleasanton. 94588</p>
          <p>sales@freightsmart.ai</p>
          &nbsp;
        </div>
        <div></div>
        <div></div>
        <Copyright />
      </div>
    </footer>
  );
};

export default Footer;
