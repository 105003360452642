import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Box,
  Grid,
  FormControl,
  InputLabel,
  useTheme,
  TextField,
  Select,
} from "@mui/material";
import { TYPES } from "../../utils";

type Props = {
  load: TYPES.Load;
  action: string;
  fnOnSaveLoad: (load: TYPES.Load) => void;
  fnOnUpdateLoad: (load: TYPES.Load) => void;
}

const LoadForm: React.FC<Props> = (props) => {
  const { load, action } = props;
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  const formik = useFormik({
    initialValues: { ...load },

    validationSchema: Yup.object().shape({
      origin: Yup.string().min(2).max(200).required("Origin is required."),
      destination: Yup.string().min(2).max(200).required("Origin is required."),
      broker: Yup.string().min(2).max(75).required("Broker is required."),
      rate: Yup.number().positive().required('Rate is required.'),
      equipment: Yup.string().min(2).max(75).required("Equipment is required."),

    }),

    onSubmit: (values) => {
      // const trimmed_data = {};
      // Object.keys(values).forEach(
      //   (key) =>
      //   (trimmed_data[key] =
      //     typeof values[key] === "string" ? values[key].trim() : values[key])
      // );
      // formAction === "Save"
      //   ? fnOnSaveData(trimmed_data)
      //   : fnOnUpdateData(trimmed_data);
    },
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Origin"
              name="origin.name"
              value={formik.values.origin.name}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.origin && formik.errors.origin)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Broker Email"
              name="destination.name"
              value={formik.values.destination.name}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.destination && formik.errors.destination)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Broker Name"
              name="broker.name"
              type="tel"
              value={formik.values.broker.name}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.broker && formik.errors.broker)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                m: "10,0,0,0",
                width: "fit-content",
              }}
            >
              {action}
            </Button>
          </Box>
        </Grid>
      </form>
    </Box>
  )
}

export default LoadForm;
