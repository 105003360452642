import { Any } from '../../utils/types';
import { InputSelectIcon } from './CustomIcons';

export default function Select(_theme: Any) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
    },
  };
}
