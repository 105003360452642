import { alpha } from '@mui/material/styles';

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export const COLORS = {
  red700: '#A90121',
  red600: '#C52F4C',
  red500: '#DC425F',
  red50020: '#F8D9DF',
  red10: '#F4E5E7',
  green700: '#659D2F',
  green500: '#82C441',
  green300: '#95DF4D',
  green30010: '#F5FCEE',
  green100: '#CFE4BA',
  green10: '#ECF1E7',
  gray900: '#131313',
  gray90070: '#575757',
  gray90080: '#404040',
  gray850: '#1C1C1C',
  gray800: '#232323',
  gray80010: '#4D4D4D',
  gray80070: '#666666',
  gray700: '#353848',
  gray500: '#6A6969',
  gray50050: '#B5B5B5',
  gray50070: '#979797',
  gray200: '#AFADAB',
  gray20010: '#F7F7F7',
  gray150: '#CCCCCC',
  gray100: '#E5E5E5',
  gray50: '#F6F6F6',
  gray3: '#EEEEEE',
  white70: '#B8B8B8',
  white40: '#717171',
  white10: '#2A2A2A',
  white: '#fff',
  orange: '#FF802D',
  orange10: '#F7EBE2',
  yellow: '#F9B70E',
  yellow10: '#FFFBEA',
  blueChart: '#60CAE4',
  blue10: '#F0FAFD',
  error: '#FF0909',
  chartBlue: '#6E8DFF',
  chartPink: '#DB73E4',
  chartRed: '#FF748D',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F6F6F6',
  200: '#CECECE',
  300: '#B3B3B3',
  400: '#979797',
  500: '#7C7C7C',
  600: '#606060',
  700: '#444444',
  800: '#232323',
  900: '#131313',
  500_8: alpha('#7C7C7C', 0.08),
  500_12: alpha('#7C7C7C', 0.12),
  500_16: alpha('#7C7C7C', 0.16),
  500_24: alpha('#7C7C7C', 0.24),
  500_32: alpha('#7C7C7C', 0.32),
  500_48: alpha('#7C7C7C', 0.48),
  500_56: alpha('#7C7C7C', 0.56),
  500_80: alpha('#7C7C7C', 0.8),
  AMAX: '#131313',
  MAX: '#000000',
  MID: '#353848',
};

const PRIMARY = {
  lighter: '#EA8D9F',
  light: '#E3677F',
  main: '#DC425F',
  dark: '#C52F4C',
  darker: '#A90121',
  contrastText: GREY[0],
};
const SECONDARY = {
  lighter: '#CFE4BA',
  light: '#95DF4D',
  main: '#82C441',
  dark: '#659D2F',
  darker: '#07650C',
  contrastText: GREY[800],
};
const LIGHT_GREY = {
  lighter: GREY[0],
  light: GREY[0],
  main: GREY[100],
  dark: GREY[200],
  darker: GREY[300],
  contrastText: GREY[700],
};
const DARK_GREY = {
  lighter: GREY[600],
  light: GREY[700],
  main: GREY[800],
  dark: GREY[900],
  darker: GREY.MAX,
  contrastText: GREY[0],
};
const LIGHT = {
  lighter: GREY[0],
  light: GREY[0],
  main: GREY[0],
  dark: GREY[200],
  darker: GREY[300],
  contrastText: GREY[900],
};
const DARK = {
  lighter: GREY[800],
  light: GREY[900],
  main: GREY.AMAX,
  dark: GREY.MAX,
  darker: GREY.MAX,
  contrastText: GREY[0],
};
const INFO = {
  lighter: '#D6F9FE',
  light: '#85DDFD',
  main: '#34AEF9',
  dark: '#1A66B3',
  darker: '#093377',
  contrastText: GREY[900],
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#A9F27C',
  main: '#51d429',
  dark: '#209814',
  darker: '#07650C',
  contrastText: GREY[900],
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#b98303',
  darker: '#6e4803',
  contrastText: GREY[900],
};
const ERROR = {
  lighter: '#FF6B6B',
  light: '#FF3A3A',
  main: '#FF0909',
  dark: '#CC0707',
  darker: '#990505',
  contrastText: GREY[0],
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const COMMON = {
  common: { black: GREY.MAX, white: GREY[0] },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  lightGrey: { ...LIGHT_GREY },
  darkGrey: { ...DARK_GREY },
  light: { ...LIGHT },
  dark: { ...DARK },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  colors: COLORS,
  text: {
    primary: '#6A6969',
    secondary: '#868686',
    disabled: '#CCCCCC',
    white: alpha('#FFFFFF', 0.7),
  },
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: GREY[0], default: GREY[100], neutral: GREY[100] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: GREY[0], secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
