import React from 'react';

import ChartCard from '../../components/components/ChartCard';
import { ActivityChartCard, CustomDataTable } from '../../components/components';
import { TYPES } from '../../utils';
import { loads, loadsByState, products } from '../../utils/mockData';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const loadColumns = [
    {
      name: 'State Code',
      width: '50%',
      selector: (row: TYPES.LoadByState) => <span className='text-light-blue font-medium text-xs'>{row.stateCode}</span>,
      style: {
        borderRightWidth: '1px',
        borderRightColor: '#F6F6F8',
        borderRightStyle: 'solid',
      }
    },
    {
      name: 'Load In',
      width: '25%',
      selector: (row: TYPES.LoadByState) => <Link to={`/search-load?loadin=${row.stateCode}`} className='text-blue cursor-pointer'>{row.loadIn}</Link>,
      style: {
        borderRightWidth: '1px',
        borderRightColor: '#F6F6F8',
        borderRightStyle: 'solid',
      }
    },
    {
      name: 'Load Out',
      width: '25%',
      selector: (row: TYPES.LoadByState) => <Link to={`/search-load?loadout=${row.stateCode}`} className='text-blue cursor-pointer'>{row.loadOut}</Link>,
    },
  ];

  const listColumns = [
    {
      name: '#',
      width: '12%',
      selector: (row: TYPES.LoadList) => <span className='text-light-blue font-semibold text-xs'>{row.id}</span>,
      style: {
        borderRightWidth: '1px',
        borderRightColor: '#F6F6F8',
        borderRightStyle: 'solid',
        color: '#808191',
        fontSize: 12,
      }
    },
    {
      name: 'TASK',
      width: '42%',
      selector: (row: TYPES.LoadList) => <span className='text-blue text-xs'>{row.rate}</span>,
      style: {
        borderRightWidth: '1px',
        borderRightColor: '#F6F6F8',
        borderRightStyle: 'solid',
      }
    },
    {
      name: 'START',
      width: '34%',
      selector: (row: TYPES.LoadList) => <span className='text-orange font-medium text-xs'>{row.pickupTime.toLocaleString()}</span>,
      style: {
        borderRightWidth: '1px',
        borderRightColor: '#F6F6F8',
        borderRightStyle: 'solid',
      }
    },
    {
      name: 'EFFORT',
      width: '12%',
      selector: (row: TYPES.LoadList) => <span className='text-heavy-blue text-xs font-medium'>{row.effort}</span>,
    },
  ];

  return (
    <div className='px-5 py-6 lg:p-9 flex flex-col gap-5'>
      <div className='grid grid-cols-2 lg:grid-cols-4 gap-2.5 lg:gap-5'>
        {products.map((item, index) => (
          // @ts-ignore
          <ChartCard
            key={index}
            {...item}
          />
        ))}
      </div>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-y-5 lg:gap-x-5'>
        <div className='flex flex-col col-span-2 gap-5'>
          <ActivityChartCard
            data={[140, 120, 150, 125, 180, 135, 145, 140, 160]}
            classes='col-span-2'
          />
          <div>
            <CustomDataTable
              columns={listColumns}
              data={loads}
            />
          </div>
        </div>
        <CustomDataTable
          columns={loadColumns}
          data={loadsByState}
        />
      </div>
    </div>
  )
};

export default Dashboard;
