import React, { FC } from 'react';

type Props = {
  classes?: string;
  children?: any;
}

const CardContainer: FC<Props> = (props) => {
  const { classes = '', children } = props;

  return (
    <div className={`flex flex-col bg-white rounded ${classes}`}>
      {children}
    </div>
  )
}

export default CardContainer;
