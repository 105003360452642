import React from 'react';
import Navbar from './Navbar';
import HeroSection from './HeroSection';
import FeatureSection from './FeatureSection';
import Workflow from './Workflow';
import Pricing from './Pricing';
import Testimonials from './Testimonials';
import Footer from './Footer';
import AboutUs from './AboutUs';

export default function LandingPage() {
  return (
    <>
      <Navbar />
      <div className="max-w-7xl mx-auto pt-20 px-6">
        <HeroSection />
        <FeatureSection />
        {/* <Workflow /> */}
        
        {/* <Pricing /> */}
        <AboutUs /> 
       
        <Footer />
      </div>
    </>
  );
}