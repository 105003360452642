const SetStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

const GetStorage = (key: string) => {
  return localStorage.getItem(key);
};

const DeleteStorage = (key: string) => {
  localStorage.removeItem(key);
};

export {
  SetStorage,
  GetStorage,
  DeleteStorage,
};
