import React, { FC, useState } from 'react';
import DataTable from 'react-data-table-component';
import { THEME } from '../../utils';

type Props = {
  columns: any[];
  data: any[];
  classes?: string;
}

const CustomDataTable: FC<Props> = (props) => {
  const { columns, data, classes = '' } = props;

  const [selected, setSelected] = useState<any>(null)

  return (
    <div className={`w-full ${classes}`}>
      <DataTable
        customStyles={{
          headCells: {
            style: {
              backgroundColor: THEME.COLORS.heavyBlue,
              color: THEME.COLORS.profileGray,
              borderRightWidth: '1px',
              borderRightColor: THEME.COLORS.orange,
              borderRightStyle: 'solid',
            },
          },
          header: {
            style: {
              backgroundColor: THEME.COLORS.orange,
            },
          },
          rows: {
            style: {
              borderBottomColor: '#fff !important',
            },
          },
          cells: {
            style: {
              borderBottomWidth: 0,
            }
          }
        }}
        conditionalRowStyles={[
          {
            when: row => selected && row.id === selected.id,
            style: {
              backgroundColor: THEME.COLORS.yellow,
            }
          }
        ]}
        columns={columns}
        data={data}
        onRowClicked={e => setSelected(e)}
      />
    </div>
  )
}

export default CustomDataTable;
