const COLORS = {
  white: '#FFF',
  dark: '#002044',
  black: '#141522',
  profileGray: '#C3C4CF',
  sidebarGray: '#B4B4C8',
  sidbarOrange: '#FD7C07',
  orange: '#E65C19',
  bgGray: '#F6F6F8',
  borderGray: '#E0E0E6',
  blue: '#355DFF',
  darkBlue: '#342CAD',
  heavyBlue: '#1C1678',
  lightBlue: '#808191',
  persimmon: '#E65C19',
  green: '#4FBF67',
  yellow: '#FFF3E8',
}

export {
  COLORS,
}
