import * as ICONS from "./icons";

const MENU_LIST = [
  {
    icon: ICONS.dashboard,
    activeIcon: ICONS.dashboardActive,
    title: 'Dashboard',
    link: '/app/dashboard',
  },
  {
    icon: ICONS.searchLoads,
    activeIcon: ICONS.searchLoadsActive,
    title: 'Search Loads',
    link: '/app/loads',
  },
  {
    icon: ICONS.searchTrucks,
    activeIcon: ICONS.searchTrucksActive,
    title: 'Search Trucks',
    link: '/app/trucks',
  },
  {
    icon: ICONS.privateLoads,
    activeIcon: ICONS.privateLoadsActive,
    title: 'Private Loads',
    link: '/app/private-loads',
  },
  {
    icon: ICONS.reports,
    activeIcon: ICONS.reportsActive,
    title: 'Reports',
    link: '/app/reports',
  },
  {
    icon: ICONS.settings,
    activeIcon: ICONS.settingsActive,
    title: 'Settings',
    link: '/app/settings',
  },
];

const AccessToken = 'AccessToken'


export {
  MENU_LIST,
  AccessToken,
}
