import { CssBaseline } from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import PropTypes, { type InferProps } from 'prop-types';
import React, { useMemo } from 'react';

import useThemeMode from '../hooks/useThemeMode';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import typography from './typography';

const ThemeProviderPropTypes = {
  children: PropTypes.node.isRequired,
};

type ThemeProviderTypes = InferProps<typeof ThemeProviderPropTypes>;

function ThemeProvider({ children }: ThemeProviderTypes) {
  const themeMode = useThemeMode();

  const isLight = themeMode === 'light';

  const themeOptions: any = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      spacing: 4,
    }),
    [isLight],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeProvider.propTypes = ThemeProviderPropTypes;

export default ThemeProvider;
