import { Box, Card, CardContent } from '@mui/material';
import smartboard from '../../assets/smartboard.png';
import smarttms from '../../assets/smartTMS.png';

const HeroSection = () => {
  return (
    <div className="flex flex-col items-center mt-6 lg:mt-20">
      <h1 className="text-4xl sm:text-6xl lg:text-7xl text-center tracking-wide">
        {" "} Freight Smart
      </h1>
      <p className="mt-10 text-lg text-center text-orange-500 max-w-4xl">
        Freight optimization and Transportation Management Systems (TMS) are crucial components of modern logistics and supply chain management. At Freight Smart, we’re redefining the future of logistics with cutting-edge technology and innovative solutions. Our mission is to empower businesses with intelligent freight and transportation management systems that drive efficiency, enhance visibility, and optimize every aspect of their supply chain
      </p>
      <div id="products" className="flex mt-10 justify-center">

        <a href='https://www.freightsmart.ai' className='rounded-lg w-1/2 border-orange-700 dark:shadow-gray-800 mx-2 my-4'>
          <img
            src={smartboard}
            alt="Logo"
            className='transition duration-300 ease-in-out hover:scale-105'
          />
        </a>
        <a href='https://www.chucktruck.co' className='rounded-lg w-1/2 border-orange-700 dark:shadow-gray-800 mx-2 my-4'>
          <img
            src={smarttms}
            alt="Logo"
            className='transition duration-300 ease-in-out hover:scale-105'
          />
        </a>
        {/* <div className="rounded-lg w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4">
          <Box>
            <Card>
              <CardContent>
                <img className="rounded-lg w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4" src={smartboard} alt="Logo" /> 
              </CardContent>
            </Card>
          </Box>
        </div>     */}
        {/* <video
          autoPlay
          loop
          muted
          className="rounded-lg w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video
          autoPlay
          loop
          muted
          className="rounded-lg w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          <source src={video2} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
      </div>
    </div>
  );
};

export default HeroSection;
