import React, { FC } from 'react';
import Chart from "react-apexcharts";

import { ICONS } from '../../utils';

type Props = {
  title: string;
  icon: any;
  value: number;
  growth: number;
  isBlue?: boolean;
  data: number[];
}

const ChartCard: FC<Props> = (props) => {
  const { title, icon, value, growth, isBlue = true, data } = props;

  const options = {
    chart: {
      type: 'area',
      height: 100,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      colors: [isBlue ? '#2E47F9' : '#E65C19'],
      width: 1,
    },
    fill: {
      type: 'gradient',
      colors: [isBlue ? '#2E47F9' : '#E65C19'],
      gradient: {
        type: "vertical",
        gradientToColors: ['#FFFFFF00']
      }
    },
    grid: {
      show: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      }
    },
    yaxis: {
      min: 0,
      labels: {
        show: false,
      }
    },
    legend: {
      horizontalAlign: 'left'
    }
  }

  return (
    <div className='flex flex-col bg-white rounded'>
      <div className='flex flex-row gap-3 p-4 lg:p-6 border-b'>
        <div className={`flex items-center justify-center w-12 h-12 rounded-full ${isBlue ? 'bg-blue' : 'bg-persimmon'}`}>
          <img src={icon} alt='img' />
        </div>
        <div className='flex flex-col'>
          <span className='text-base lg:text-lg text-medium text-[#141522]'>
            {title}
          </span>
          <span className='text-sm text-[#808191]'>
            Dollar
          </span>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='px-6 pt-8 flex flex-col gap-2'>
          <span className='text-2xl font-medium'>{value} USD</span>
          <div className='flex flex-row items-center gap-2'>
            <img src={ICONS.arrowUp} alt='up' />
            <span className='text-green'>+{growth}%</span>
          </div>
        </div>
        <div className='-mt-5 -ml-[22px] -mr-2 -mb-8'>
          <Chart
            series={[{
              name: title,
              data
            }]}
            // @ts-ignore
            options={options}
            type='area'
            height={130}
          />
        </div>
      </div>
    </div>
  )
}

export default ChartCard;
