import axios from 'axios';

export const AxiosInstance = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

AxiosInstance.interceptors.request.use(
	(config) => config,
	(error) => {
			return Promise.reject(
					(error.response && error.response.data) || ''
			);
	}
);

AxiosInstance.interceptors.response.use(
	(response) => response,
	(error) =>
			Promise.reject(
					(error.response && error.response.data) || ''
			)
);
