import * as React from 'react';
import { format } from 'date-fns';
import { Box, Button, Divider, Stack, Typography, Drawer } from '@mui/material';
import { ICONS, IMAGES, THEME } from '../../utils'
import { Any } from '../../utils/types';
import Image from './Image';

type Props = {
  load: Any;
  onCloseDrawer: (id: number) => void;
}

const drawerWidth = 400;

const DetailDrawer: React.FC<Props> = (props) => {
  const { load, onCloseDrawer } = props;

  return (
    <>
      <Box sx={{ padding: '20px', borderBottomWidth: '1px', borderBottomColor: THEME.COLORS.borderGray }}>
        <Button
          sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', width: '44px', height: '44px', minWidth: '44px',
            borderWidth: '1px', borderStyle: 'solid', borderColor: THEME.COLORS.borderGray, borderRadius: 999,
          }}
          onClick={() => onCloseDrawer(0)}
        >
          <Image source={ICONS.close} />
        </Button>
      </Box>
      <Stack direction='column' p={5} gap={12}>
        <Stack direction='column'>
          <Stack direction='column' pt={6}>
            <Stack direction='row' alignItems='center' gap={3}>
              <Box sx={{ width: '48px', height: '48px', borderWidth: '1px', borderRadius: 999, borderColor: THEME.COLORS.borderGray, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: '18px', height: '18px', backgroundColor: THEME.COLORS.orange, borderRadius: 999 }} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', borderRightWidth: '1px', borderColor: THEME.COLORS.borderGray, width: '144px' }}>
                <Typography sx={{ fontSize: 12, color: THEME.COLORS.black, fontWeight: '500' }}>{`${load.origin.city}, ${load.origin.state}`}</Typography>
                <Typography sx={{ fontSize: 12, lineHeight: '20px', color: THEME.COLORS.lightBlue }}>{load.origin.address}</Typography>
              </Box>
              <Stack direction='column'>
                <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 12, lineHeight: '24px' }}>{format(load.pickupTime, 'PP')}</Typography>
                <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 12, lineHeight: '24px' }}>{format(load.pickupTime, 'p')}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ width: '1px', height: '50px', marginLeft: '24px', backgroundColor: THEME.COLORS.borderGray }} />
          <Stack direction='column' pb={6}>
            <Stack direction='row' alignItems='center' gap={3}>
              <Box sx={{ width: '48px', height: '48px', borderWidth: '1px', borderRadius: 999, borderColor: THEME.COLORS.borderGray, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Image source={ICONS.pin} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', borderRightWidth: '1px', borderColor: THEME.COLORS.borderGray, width: '144px' }}>
                <Typography sx={{ fontSize: 12, color: THEME.COLORS.black, fontWeight: '500' }}>{`${load.destination.city}, ${load.destination.state}`}</Typography>
                <Typography sx={{ fontSize: 12, lineHeight: '20px', color: THEME.COLORS.lightBlue }}>{load.destination.address}</Typography>
              </Box>
              <Stack direction='column'>
                <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 12, lineHeight: '24px' }}>{format(new Date(), 'PP')}</Typography>
                <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 12, lineHeight: '24px' }}>{format(new Date(), 'p')}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='column'>
          <Typography sx={{ fontSize: '32px', fontWeight: '600', lineHeight: '48px', color: THEME.COLORS.black }}>
            Heading
          </Typography>
          <Typography sx={{ color: THEME.COLORS.lightBlue, fontSize: 14 }}>
            The community's need for applications that can facilitate daily activities is increasing as technology advances. Currently, many companies are looking for developers so that they can sell products (goods or services) that can reach wider buyers online. We are not required to understand all the science of design.
          </Typography>
          <img src={IMAGES.map} style={{ marginTop: '24px' }} />
        </Stack>
      </Stack>
    </>
  )
}
export default DetailDrawer;