import React, { createContext, useEffect, useState } from 'react'

import { AxiosInstance } from '../services/instance';
import { CONSTANTS, TYPES } from '../utils';
import { GetStorage, SetStorage } from '../utils/storage';

interface IAppContext {
  isAuthenticated: boolean;
  user: TYPES.User | null;
  sidebar: boolean;

  errStr: string;
  successStr: string;
  
  init: () => void,
  logout: () => void,

  updateAuthenciated: (data: any) => void;
  updateIsAuthenticated: (status: boolean) => void;
  updateUserInfo: (data: TYPES.User) => void;
  toggleSidebar: (status: boolean) => void;

  updateErrStr: (val: string) => void;
  updateSuccessStr: (val: string) => void;

}


const initialState = {
  isAuthenticated: false,
  user: null,
  sidebar: false,

  errStr: '',
  successStr: '',

  init: () => { },
  logout: () => { },

  updateAuthenciated: () => { },
  updateIsAuthenticated: () => { },
  updateUserInfo: () => { },
  toggleSidebar: () => { },

  updateErrStr: () => { },
  updateSuccessStr: () => { },
}

const AppContext = createContext({} as IAppContext)

export const AppDataProvider = (props: any) => {
  const { children } = props;

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(props.isAuthenticated || initialState.isAuthenticated);
  const [user, setUser] = useState<TYPES.User | null>(props.user || initialState.user);
  const [sidebar, setSidebar] = useState<boolean>(props.sidebar || initialState.sidebar);
  const [errStr, setErrStr] = useState<string>(props.errStr || initialState.errStr);
  const [successStr, setSuccessStr] = useState<string>(props.successStr || initialState.successStr);

  const handleInit = () => {
    setErrStr('');
    setSuccessStr('');
  }

  const handleAuthenticated = (data: any) => {
    const { tokens, user } = data;

    setUser(user);
    SetStorage(CONSTANTS.AccessToken, tokens.access.token);
    AxiosInstance.defaults.headers.common.Authorization = `Bearer ${tokens.access.token}`;
    setIsAuthenticated(true);
  }

  useEffect(() => {
    if (GetStorage(CONSTANTS.AccessToken)) {
      AxiosInstance.defaults.headers.common.Authorization = `Bearer ${GetStorage(CONSTANTS.AccessToken)}`;
    }
  }, [])

  const handleIsAuthenticated = (status: boolean) => {
    setIsAuthenticated(status);

  }
  const handleUserInfo = (data: TYPES.User) => {
    setUser(data);
  }

  const handleErrStr = (str: string) => {
    setErrStr(str);
  }

  const handleSuccessStr = (str: string) => {
    setSuccessStr(str);
  }

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUser(null);
    delete AxiosInstance.defaults.headers.common.Authorization
    handleInit();
  }

  const handleSidebar = (status: boolean) => {
    setSidebar(status);
  }

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        user,
        sidebar,
        errStr,
        successStr,

        updateAuthenciated: handleAuthenticated,
        updateIsAuthenticated: handleIsAuthenticated,
        updateUserInfo: handleUserInfo,
        toggleSidebar: handleSidebar,
        updateErrStr: handleSuccessStr,
        updateSuccessStr: handleErrStr,
        init: handleInit,
        logout: handleLogout,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext