import React from 'react';

const Settings = () => {
  return (
    <div className='p-9'>
      <h1>Settings</h1>
    </div>
  )
};

export default Settings;
