import { type Any } from '../../utils/types';

export default function Icon(_theme: Any) {
  return {
    MuiIcon: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  };
}
