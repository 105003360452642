import React, { FC, useEffect, useState } from 'react';
import StateCode from '../../utils/state_code.json';
import { THEME } from '../../utils';
import { Autocomplete, TextField, Typography } from '@mui/material';

type Props = {
  value: string;
  name: string;
  styles?: any;
  onChange: (code: string) => void;
}

const AutoCompleteState: FC<Props> = (props) => {
  const { value, name, styles = null, onChange } = props;

  const [selected, setSelected] = useState<string>('');

  useEffect(() => {
    if (value) {
      const filtered = StateCode.find(item => item.code === value);
      if (filtered)
        setSelected(`${filtered.code} (${filtered.state})`)
      else
        setSelected(value)
    } else {
      setSelected('')
    }
  }, [value])

  const handleChange = (val: string | null) => {
    if (val) {
      const code = val.split(' (')[0];
      onChange(code);
    } else
      onChange('');
  }

  return (
    <Autocomplete
      freeSolo
      clearIcon={null}
      value={selected}
      options={StateCode.map(item => (`${item.code} (${item.state})`))}
      renderOption={(props, option) => (
        <Typography component='li' sx={{ height: '44px', fontSize: '12px' }} {...props}>
          {option}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            height: '44px',
            '& input': { fontSize: 12 },
            '&::placeholder': { color: THEME.COLORS.black },
            '& .MuiOutlinedInput-root': { paddingRight: '9px !important', height: '44px' },
            '@media screen and (max-width: 640px)': {
              height: '40px',
            }
          }}
          placeholder={name}
        />
      )}
      sx={{
        height: '44px',
        borderRadius: '4px',
        outline: 'none',
        border: 'none',
        backgroundColor: 'white',
        fontSize: '12px',
        '& fieldset': {
          border: 'none',
        },
        ...styles,
      }}
      onChange={(event: any, newValue: string | null) => {
        handleChange(newValue)
      }}
      onInputChange={(event: any, newValue: string | null) => {
        handleChange(newValue)
      }}
    />
  )
}

export default AutoCompleteState;
