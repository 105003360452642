import * as ICONS from './icons';
import { TYPES } from '.'

const loadsByState = [
  { id: 0, stateCode: 'AK', loadIn: 2, loadOut: 0 , },
  { id: 1, stateCode: 'AL', loadIn: 138, loadOut: 316 , },
  { id: 2, stateCode: 'AR', loadIn: 74, loadOut: 258 ,},
  { id: 3, stateCode: 'AZ', loadIn: 142, loadOut: 561 ,},
  { id: 4, stateCode: 'CA', loadIn: 499, loadOut: 1545 ,},
  { id: 5, stateCode: 'CO', loadIn: 259, loadOut: 172 ,},
  { id: 6, stateCode: 'CT', loadIn: 92, loadOut: 29 ,},
  { id: 7, stateCode: 'DC', loadIn: 127, loadOut: 98 ,},
  { id: 8, stateCode: 'DE', loadIn: 30, loadOut: 8 ,},
  { id: 10, stateCode: 'FL', loadIn: 640, loadOut: 241 ,},
  { id: 11, stateCode: 'GA', loadIn: 341, loadOut: 668 ,},
  { id: 12, stateCode: 'GL', loadIn: 231, loadOut: 115 ,},
  { id: 13, stateCode: 'HA', loadIn: 587, loadOut: 298 ,},
  { id: 14, stateCode: 'RC', loadIn: 986, loadOut: 126 ,},
  { id: 15, stateCode: 'US', loadIn: 256, loadOut: 118 ,},
]

const loads: TYPES.LoadList[] = [
  {
    id:1,
    origin: {
      city: 'Seattle',
      state: 'WA',
      country: 'USA',
      address: '123 Pike Place',
      latitude: 47.6062,
      longitude: -122.3321
    },
    destination: {
      city: 'Portland',
      state: 'OR',
      country: 'USA',
      address: '456 Hawthorne St',
      latitude: 45.5152,
      longitude: -122.6784
    },
    broker: {
      name: 'Pacific Freight',
      address: '789 Market St, Suite 301',
      rating: 4.5,
      status: 'Active',
      contact: {
        name: 'Jessica Adams',
        email: 'jessica.adams@pacificfreight.com',
        phone: '+1-555-234-5678'
      }
    },
    pickupTime: new Date(),
    rate: 900.00,
    equipment: {
      type: 'Flatbed'
    }
  },
  {
    id: 2,
    origin: {
      city: 'Denver',
      state: 'CO',
      country: 'USA',
      address: '789 Broadway',
      latitude: 39.7392,
      longitude: -104.9903
    },
    destination: {
      city: 'Kansas City',
      state: 'MO',
      country: 'USA',
      address: '321 Main St',
      latitude: 39.0997,
      longitude: -94.5786
    },
    broker: {
      name: 'Mountain Movers',
      address: '1010 Summit Ave, Suite 102',
      rating: 4.7,
      status: 'Active',
      contact: {
        name: 'Mark Wilson',
        email: 'mark.wilson@mountainmovers.com',
        phone: '+1-555-345-6789'
      }
    },
    pickupTime: new Date(),
    rate: 1100.00,
    equipment: {
      type: 'Flatbed'
    }
  },
  {
    id: 3,
    origin: {
      city: 'Atlanta',
      state: 'GA',
      country: 'USA',
      address: '456 Peachtree St',
      latitude: 33.7490,
      longitude: -84.3880
    },
    destination: {
      city: 'Charlotte',
      state: 'NC',
      country: 'USA',
      address: '123 Trade St',
      latitude: 35.2271,
      longitude: -80.8431
    },
    broker: {
      name: 'Southern Logistics',
      address: '2020 South Blvd, Suite 201',
      rating: 4.6,
      status: 'Active',
      contact: {
        name: 'Emily Johnson',
        email: 'emily.johnson@southernlogistics.com',
        phone: '+1-555-456-7890'
      }
    },
    pickupTime: new Date(),
    rate: 1300.00,
    equipment: {
      type: 'Flatbed'
    }
  },
  {
    id: 4,
    origin: {
      city: 'San Francisco',
      state: 'CA',
      country: 'USA',
      address: '789 Mission St',
      latitude: 37.7749,
      longitude: -122.4194
    },
    destination: {
      city: 'Las Vegas',
      state: 'NV',
      country: 'USA',
      address: '123 Strip Blvd',
      latitude: 36.1699,
      longitude: -115.1398
    },
    broker: {
      name: 'Western Haulers',
      address: '303 Bay St, Suite 104',
      rating: 4.8,
      status: 'Active',
      contact: {
        name: 'Robert Lee',
        email: 'robert.lee@westernhaulers.com',
        phone: '+1-555-678-9012'
      }
    },
    pickupTime: new Date(),
    rate: 1400.00,
    equipment: {
      type: 'Flatbed'
    }
  },
  {
    id: 5,
    origin: {
      city: "New York",
      state: "NY",
      country: "USA",
      address: "101 Broadway",
      latitude: 40.7128,
      longitude: -74.0060
    },
    destination: {
      city: "Philadelphia",
      state: "PA",
      country: "USA",
      address: "202 Market St",
      latitude: 39.9526,
      longitude: -75.1652
    },
    broker: {
      name: "Metro Freight",
      address: "500 Park Ave, Suite 600",
      rating: 4.3,
      status: "Active",
      contact: {
        name: "Linda Carter",
        email: "linda.carter@metrofreight.com",
        phone: "+1-555-123-4567"
      }
    },
    pickupTime: new Date(),
    rate: 850.00,
    equipment: {
      type: "Van"
    }
  },
  {
    id: 6,
    origin: {
      city: "Dallas",
      state: "TX",
      country: "USA",
      address: "303 Main St",
      latitude: 32.7767,
      longitude: -96.7970
    },
    destination: {
      city: "Austin",
      state: "TX",
      country: "USA",
      address: "404 Sixth St",
      latitude: 30.2672,
      longitude: -97.7431
    },
    broker: {
      name: "Lone Star Logistics",
      address: "1000 Elm St, Suite 300",
      rating: 4.6,
      status: "Active",
      contact: {
        name: "James Thompson",
        email: "james.thompson@lonestarlogistics.com",
        phone: "+1-555-234-5678"
      }
    },
    pickupTime: new Date(),
    rate: 950.00,
    equipment: {
      type: "Van"
    }
  },
  {
    id: 7,
    origin: {
      city: "San Diego",
      state: "CA",
      country: "USA",
      address: "505 Coast Blvd",
      latitude: 32.7157,
      longitude: -117.1611
    },
    destination: {
      city: "San Francisco",
      state: "CA",
      country: "USA",
      address: "606 Golden Gate Ave",
      latitude: 37.7749,
      longitude: -122.4194
    },
    broker: {
      name: "Golden Gate Transport",
      address: "2020 Pacific Ave, Suite 400",
      rating: 4.8,
      status: "Active",
      contact: {
        name: "Natalie Green",
        email: "natalie.green@goldengatetransport.com",
        phone: "+1-555-345-6789"
      }
    },
    pickupTime: new Date(),
    rate: 1150.00,
    equipment: {
      type: "Van"
    }
  },
  {
    id: 8,
    origin: {
      city: "Chicago",
      state: "IL",
      country: "USA",
      address: "707 Lakeshore Dr",
      latitude: 41.8781,
      longitude: -87.6298
    },
    destination: {
      city: "Detroit",
      state: "MI",
      country: "USA",
      address: "808 Woodward Ave",
      latitude: 42.3314,
      longitude: -83.0458
    },
    broker: {
      name: "Great Lakes Freight",
      address: "303 North Ave, Suite 201",
      rating: 4.5,
      status: "Inactive",
      contact: {
        name: "Paul Robinson",
        email: "paul.robinson@greatlakesfreight.com",
        phone: "+1-555-456-7890"
      }
    },
    pickupTime: new Date(),
    rate: 1200.00,
    equipment: {
      type: "Van"
    }
  }
    
]


const products = [
  {
    title: 'Total Loads',
    icon: ICONS.truck,
    isBlue: true,
    value: 18245.4,
    growth: 2.73,
    data: [30, 50, 30, 110, 50, 80, 65, 70, 50, 130, 100, 120, 50, 90]
  },
  {
    title: 'Income',
    icon: ICONS.income,
    isBlue: false,
    value: 18245.4,
    growth: 2.73,
    data: [80, 20, 60, 110, 50, 80, 65, 70, 50, 130, 100, 120, 50, 90]
  },
  {
    title: 'Profit',
    icon: ICONS.profit,
    isBlue: true,
    value: 18245.4,
    growth: 2.73,
    data: [60, 20, 30, 110, 50, 80, 65, 70, 50, 130, 100, 120, 50, 90]
  },
  {
    title: 'Total Trucks',
    icon: ICONS.totalTruck,
    isBlue: false,
    value: 18245.4,
    growth: 2.73,
    data: [50, 60, 20, 80, 30, 40, 35, 40, 20, 100, 70, 90, 30, 60]
  },
]

export {
  loads,
  loadsByState,
  products,
}
