import React from 'react';

const Reports = () => {
  return (
    <div className='p-9'>
      <h1>Reports</h1>
    </div>
  )
};

export default Reports;
